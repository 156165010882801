import React from "react";
import "./index.scss";

import CloseLogo from "../../img/close.svg";

const Popin = ({ children, openDefault }) => {
  const [isOpen, setIsOpen] = React.useState(openDefault);

  return (
    <div
      className={isOpen ? "popin popin--open" : "popin"}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <div className="popin__content" onClick={(e) => e.stopPropagation()}>
        <div
          className="btn-close"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <img
            className="logo--black"
            src={CloseLogo}
            alt="Havas Digital Factory logo"
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popin;
