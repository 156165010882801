const initialState = {
    currentTpl: 'homepage',
}

/**
 * PAGE NAME REDUCER
 * @param {*} state
 * @param {*} action
 */
export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_PAGE':
            return {...state, page: action.value}
        default:
            return state
    }
}