import { useState, useEffect } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";

/**
 * @async Creation d'un hook d'appel des API
 *
 * @param {string} name nom de l'API
 * @param {string} _uid ID du template
 * @param {string} slug nom d'article
 * @param {string} termOfSearch terme à rechercher ?s=
 * @returns {[Array, Boolean]} data Datafetch = [ JSON], isLoaded = false]
 */

export default ({ name = "", slug, _uid, termOfSearch }) => {
  const lang = useSelector((state) => state.global).lang;

  const [dataFetch, setDataFetch] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(
    () => {
      setIsLoaded(false);
      let load = false;

      const fetchAPi = async () => {
        try {
          const dataFetch = await Axios(
            `${process.env.REACT_APP_APP}/wp-json/hdf/v1/${name}/${
              lang ? `?lang=${lang}` : ""
            }${termOfSearch ? `&s=${termOfSearch}` : ""}${
              slug ? `&slug=${slug}` : ""
            }${_uid ? "&_uid=" + _uid : ""}`
            /* `${process.env.REACT_APP_APP}/wp-json/hdf/v1/${name}/${termOfSearch ? `&s=${termOfSearch}` : ''}${slug ? `&slug=${slug}` : ''}${_uid ? '&_uid=' + _uid : ''}`*/
          );

          if (!load) setDataFetch(dataFetch.data);
          if (!load) setIsLoaded(true);
          // console.log(name, dataFetch.config.url, dataFetch.data);
        } catch (err) {
          // console.err(err)
        }
      };
      fetchAPi();

      return () => {
        load = true;
      };
    },
    [lang, name, _uid, slug, termOfSearch]
    //[name, _uid, slug, termOfSearch]
  );

  // si l'api renvoi un string on le converti en JSON avant de retourner
  return [
    typeof dataFetch == "string" ? JSON.parse(dataFetch) : dataFetch,
    isLoaded,
  ];
};
