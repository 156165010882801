import React from "react"
import {Link} from "react-router-dom"
import './index.scss';

import GlobalContainer from '../../containers/GlobalContainer/index'


const ExpertisesRedirect = ({title, extraClass, listItemsMenu}) => {

    return (
        <div className={`expertises-redirect__container ${extraClass}`}>
            <GlobalContainer>
                <h3 className="title">Autres expertises : <span>{title}</span></h3>
                <div className="expertises-redirect-items__container">
                    {
                        listItemsMenu.map((item, key) => {
                            return (
                                <div key={key} className="link__container">
                                    <Link to={item.url} className="link" dangerouslySetInnerHTML={{__html: item.title}}></Link>
                                </div>
                            )
                        })
                    }
                </div>
            </GlobalContainer>
        </div>
    )
}

export default ExpertisesRedirect
