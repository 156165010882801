import React, { useState } from 'react';
import App from '../../App';


import {
    MatomoProvider,
    createInstance,
} from '@datapunt/matomo-tracker-react';




function AppMatomo() {
 const [instance, setInstance] = useState(null);

 window.initMatomo = () => {
     console.log('initmatomo 1', document.cookie);
     if (document.cookie.includes('!=true') && !window.matomoDone) {
         console.log('initmatomo 2 dans le if');
         let instanceRes = createInstance({
             urlBase: 'https://havas-factory.matomo.cloud/',
             siteId: 3,
         });
         window.matomoDone = true;
         setInstance(instanceRes);
     }
      if (document.cookie.includes('!matomocloud=false')) {
       let allCookies = document.cookie.split(';');
       for (let i = 0; i < allCookies.length; i++) {
          if (allCookies[i].includes('_pk')) {
              window.tarteaucitron.cookie.purge([allCookies[i]]);
          }
       }
      }
      
 };
 window.initMatomo();

 return (
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
 )
}

export default AppMatomo;