import React, { useState } from 'react';
import { Link } from "react-router-dom"

//Import Swiper lib
import SwiperCore, { Navigation, Scrollbar, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// CONTAINERS
import GlobalContainer from '../../containers/GlobalContainer/index';

import './index.scss';

SwiperCore.use([Navigation, Scrollbar, Thumbs]);


const SliderSwiperAgence = ({ title, slides }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className="slider-swiper-agence__container">
            <GlobalContainer>
                <h2 className="slider-swiper-agence-title">{title}</h2>
            </GlobalContainer>
            <Swiper
                thumbs={{ swiper: thumbsSwiper }}
                slidesPerView={2}
                spaceBetween={40}
                style={{ background: '#f24100' }}
                // grabCursor={true}
                noSwiping={false}
                centeredSlides={true}
                allowTouchMove={true}
                slideToClickedSlide={true}
                scrollbar={{ draggable: true }}>
                {
                    slides && slides.map((slide, key) => {
                        return (
                            <SwiperSlide key={key}><p className="thumb-text">{slide.thumbText}</p></SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                noSwiping={false}
                autoHeight={true}
                grabCursor={true}
                slideToClickedSlide={true}
                allowTouchMove={false}>
                {
                    slides && slides.map((slide, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <div className="slide__container">
                                    <img className="slide-img" src={slide.imageUrl} alt={slide.imageAlt} />
                                    <GlobalContainer>
                                        {
                                            slide.text && <p className="slide-text" dangerouslySetInnerHTML={{ __html: slide.text }}></p>
                                        }
                                        {
                                            slide.cta && <Link className="slide-link" to={slide.ctaUrl}>{slide.cta}</Link>
                                        }

                                    </GlobalContainer>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}

export default SliderSwiperAgence