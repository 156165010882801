export function getElementText(el) {
  var text = "";
  // Text node (3) or CDATA node (4) - return its text
  if (el.nodeType === 3 || el.nodeType === 4) {
    text = el.nodeValue;
    // If node is an element (1) and an img, input[type=image], or area element, return its alt text
  } else {
    const alt = el.getAttribute("alt");
    if (typeof alt === "string") {
      text = el.getAttribute("alt") || "";
    } else {
      var children = el.childNodes;
      for (var i = 0, l = children.length; i < l; i++) {
        text += getElementText(children[i]);
      }
    }
  }
  return text;
}
