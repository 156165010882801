import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import "./index.scss";

export default function Columns(props) {
  return (
    <div className={classNames("columns", props.className)}>
      {props.children}
    </div>
  );
}

Columns.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Columns.Column = function Column(props) {
  return (
    <div
      className={classNames(
        "columns__column",
        `columns__column--size-${props.size}`,
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

Columns.Column.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["half"]).isRequired,
  children: PropTypes.node,
};
