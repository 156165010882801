import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Asset from "../../components/Asset";
import GlobalContainer from "../../containers/GlobalContainer/index";
import AssetService from "../../services/AssetService";
import BurgerIcon from "../Burger";
import Effect from "../Effect";
import "./index.scss";
import NavbarContext from "./NavbarContext";

export default function Mobile() {
  const globalStore = useSelector((state) => state.global);
  const { logo, links } = globalStore.global.header;

  const location = useLocation();

  useEffect(() => {
    setMenuOpened(false);
  }, [location]);

  const [menuOpened, setMenuOpened] = useState(false);
  const [onTop, setOnTop] = useState(true);

  const bigLogoVisible = useMemo(() => menuOpened || onTop, [
    onTop,
    menuOpened,
  ]);
  const smallLogoVisible = useMemo(() => !bigLogoVisible, [bigLogoVisible]);

  const navbarTheme = NavbarContext.useContext().theme;

  const theme = menuOpened ? "light" : navbarTheme;

  const logoSrc =
    theme === "light"
      ? AssetService.list.logo_mobile_white
      : AssetService.list.logo_mobile_black;

  const logoSmallSrc =
    theme === "light"
      ? AssetService.list.logo_mobile_compressed_white
      : AssetService.list.logo_mobile_compressed_black;

  const onMenuToggle = useCallback((forced) => {
    if (forced) {
      setMenuOpened(true);
    } else {
      setMenuOpened(false);
    }
  });

  useEffect(() => {
    function onScroll() {
      if (window.pageYOffset >= 1) {
        setOnTop(false);
      } else {
        setOnTop(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      className={classNames("navbar-mobile", `navbar-mobile--theme-${theme}`)}
    >
      <div className={classNames("navbar-mobile__inner")}>
        <Link
          className={classNames(
            `navbar-mobile__big-logo`,
            `navbar-mobile__big-logo--visible-${bigLogoVisible ? "yes" : "no"}`
          )}
          to={logo.url}
        >
          <Asset descriptor={logoSrc} alt="Havas Digital Factory logo" />
        </Link>
        <BurgerIcon toggled={menuOpened} toggle={onMenuToggle} />
        <div
          className={classNames(
            "navbar-mobile__small-logo",
            `navbar-mobile__small-logo--visible-${
              smallLogoVisible ? "yes" : "no"
            }`
          )}
        >
          <Asset descriptor={logoSmallSrc} />
        </div>
      </div>
      <CSSTransition
        in={menuOpened}
        timeout={200}
        className="navbar-mobile__menu"
        unmountOnExit
      >
        <div>
          <ul className={classNames(`navbar-mobile__items`)}>
            {links.map((link, key) => {
              return (
                <li key={key} className={"navbar-mobile__item"}>
                  <Link to={link.url} className="navbar-mobile__link">
                    {link.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
}

/*

        <div className={classNames("navbar-desktop__bar")}>
          
            
          </Link>
          
        </div>
        <div
          className={classNames(
            "navbar-desktop__small-logo",
            `navbar-desktop__small-logo--visible-${
              smallLogoVisible ? "yes" : "no"
            }`
          )}
        >
          <img src={logoSmallSrc} alt="Havas Digital Factory logo" />
        </div>
      </div>
*/
