import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../../store/action";
import { useLocation } from "react-router-dom";
import useApi from "../../utils/useApi";
import "./index.scss";

// import config from '../../config/works.json'

// COMPONENTS
import Loader from "../../components/Loader";
import Metatags from "../../components/MetasTags";
import ImageOverlay from "../../components/ImageOverlay/index.js";

// CONTAINER
import GlobalContainer from "../../containers/GlobalContainer/index.js";

const Works = ({ _uid }) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-works"));
  }, [dispatch]);

  const location = useLocation();
  const [dataFetch, isLoaded] = useApi({
    slug: location.pathname,
    name: "page",
    _uid,
  });
  const { metas, title, blockFiveImg } = dataFetch;
  // const isLoaded = true;
  // const { metas, title, blockFiveImg } = config;

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...metas} />
          <div className="works__container">
            <GlobalContainer>
              <h2 className="title-page">{title}</h2>
            </GlobalContainer>
            {blockFiveImg.map((block, key) => {
              return (
                <div key={key} className="works-block__container">
                  <div className="small-column">
                    <ImageOverlay
                      title={block.works[0].title}
                      url={block.works[0].url}
                      imgSrc={block.works[0].imgSrc}
                      textHover={block.works[0].textHover}
                      percentSize={100}
                      overlayColor={block.works[0].overlayColor}
                    />
                    <ImageOverlay
                      title={block.works[3].title}
                      url={block.works[3].url}
                      imgSrc={block.works[3].imgSrc}
                      textHover={block.works[3].textHover}
                      percentSize={100}
                      overlayColor={block.works[3].overlayColor}
                    />
                  </div>
                  <div className="big-column">
                    <div className="line-top">
                      <ImageOverlay
                        title={block.works[2].title}
                        url={block.works[2].url}
                        imgSrc={block.works[2].imgSrc}
                        textHover={block.works[2].textHover}
                        percentSize={100}
                        overlayColor={block.works[2].overlayColor}
                      />
                      <ImageOverlay
                        title={block.works[1].title}
                        url={block.works[1].url}
                        imgSrc={block.works[1].imgSrc}
                        textHover={block.works[1].textHover}
                        percentSize={100}
                        overlayColor={block.works[1].overlayColor}
                      />
                    </div>
                    <div className="line-bottom">
                      <ImageOverlay
                        title={block.works[4].title}
                        url={block.works[4].url}
                        imgSrc={block.works[4].imgSrc}
                        textHover={block.works[4].textHover}
                        percentSize={100}
                        overlayColor={block.works[4].overlayColor}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Works;
