import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Navbar from "../../components/Navbar";
import NavbarContext from "../../components/Navbar/NavbarContext";
import Footer from "../../components/Footer";
import Theme from "../../components/Theme";
import Effect from "../../components/Effect";
import Appear from "../../components/Appear";
import Button from "../../components/Button";
import config from "../../config/404.json";
import GlobalContainer from "../../containers/GlobalContainer/index";
import { updateGlobalStorePage } from "../../store/action";
import "./index.scss";

export default function NotFound() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-404"));
  }, [dispatch]);

  const { title, text, secondText, button } = config;

  const { setTheme } = NavbarContext.useContext();

  useEffect(() => {
    setTheme("light");
  }, []);

  function renderDesktop() {
    return (
      <GlobalContainer className="not-found not-found--desktop">
        <div>
          <div className="typo--extra-fat">{config.title.desktop}</div>
          <div className="typo--fat">
            <Appear
              html={config.text.desktop}
              effect={Effect.Ping.effects.not_found}
            ></Appear>
          </div>
        </div>
        <div>
          <Button theme="light" href={button.url}>
            {button.text}
          </Button>
        </div>
      </GlobalContainer>
    );
  }

  function renderMobile() {
    return (
      <GlobalContainer className="not-found not-found--mobile">
        <div>
          <div className="typo--fat">
            <Appear
              html={config.title.mobile}
              effect={Effect.Ping.effects.not_found}
            ></Appear>
          </div>
          <div className="typo--fat">{config.text.mobile}</div>
        </div>
        <div>
          <Button theme="light" href={button.url}>
            {button.text}
          </Button>
        </div>
      </GlobalContainer>
    );
  }

  return (
    <Theme theme="bg-pink">
      <Navbar.Spacer />
      {renderDesktop()}
      {renderMobile()}
      <Footer mode="folded" />
    </Theme>
  );
}

// const NotFound = () => {
//   return (
//     <>
//       <GlobalContainer>
//         <div className="notFound__container">
//           <h2 className="title">{title}</h2>
//           <p>{text}</p>
//           <p>
//             <span>{secondText}</span>
//           </p>
//           <Link to={button.url}>
//             <button>{button.text}</button>
//           </Link>
//         </div>
//       </GlobalContainer>
//     </>
//   );
// };

// export default NotFound;
