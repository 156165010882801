import classNames from "classnames";
import React, { createContext } from "react";
import "./index.scss";

const Context = createContext();

export default function Theme(props) {
  return (
    <Context.Provider value={props.theme}>
      <div
        className={classNames(
          "theme",
          `theme--${props.theme}`,
          props.className
        )}
      >
        {props.children}
      </div>
    </Context.Provider>
  );
}

Theme.Context = Context;
