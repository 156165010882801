import React from "react"
import './index.scss';
import {Link} from "react-router-dom"

const MenuBubble = ({itemsMenu, getClickedKey, indexSelected, pageExpertises, onClick}) => {

    const handleClick = (key) => {
        window.history.replaceState(null, "", itemsMenu[key].url);
    }

    return (
        <div className="menu-bubble__container">
            {
                itemsMenu &&
                itemsMenu.map((item, key) => {
                    return (
                        <div className={`link ${key === indexSelected ? `active-menu` : ``}`} key={key}
                             onClick={(e) => {
                                 getClickedKey(key);
                                 handleClick(key);
                             }}>
                            <div className="link-text">
                                {
                                    (item.url !== undefined && !pageExpertises) ?
                                        <Link to={item.url} onClick={onClick}>
                                            <p dangerouslySetInnerHTML={{__html: item.title}}></p>
                                        </Link> :
                                        <p dangerouslySetInnerHTML={{__html: item.title}}></p>
                                }
                            </div>
                            <div className="bubble">&nbsp;</div>
                        </div>
                    )
                })
            }
        </div>

    )
}

export default MenuBubble
