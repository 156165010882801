import React from "react"
import './index.scss';
import GlobalContainer from "../../../containers/GlobalContainer";

const Intro = ({title, content, link}) => {
    return (

        <div className="content__intro">
            <GlobalContainer max={true}>
                <div className="content__intro-inner">
                    {title &&
                    (
                        <div className="content__intro-actors" dangerouslySetInnerHTML={{__html: title}}></div>
                    )
                    }
                    {content &&
                    <div className="content__intro-content" dangerouslySetInnerHTML={{__html: content}}></div>}
                    {link.url &&
                    <a href={link.url} target={link.target} className="content__intro-link">{link.title}</a>}
                </div>
            </GlobalContainer>
        </div>

    )
}

export default Intro