import React from "react"
import {Scrollbars} from 'react-custom-scrollbars';
import './index.scss';
import GlobalContainer from "../../../containers/GlobalContainer";

const TextImage = ({content, img, imgPosition, device}) => {
    function renderClasses() {
        if (imgPosition === 'left' && device) {
            return 'content__textImage--reverse content__textImage--scroll'
        }
        if (imgPosition === 'left' && !device) {
            return 'content__textImage--reverse'
        }
        if (imgPosition !== 'left' && device) {
            return 'content__textImage--scroll'
        }
        return '';
    }

    return (
        <div className={`content__textImage ${renderClasses()}`}>
            <GlobalContainer max={true}>
                <div className="content__textImage-inner">
                    <div className="content__textImage-content" dangerouslySetInnerHTML={{__html: content}}></div>
                    {device ?
                        <div className={`content__textImage-img content__textImage-img--${device}`}>
                            <div className="img__inner">
                                <div className="img__ctn">
                                    <div className="img__ctn-scroll">
                                        <Scrollbars autoHide>
                                            <img src={img.url} alt={img.alt}/>
                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="img__notice">
                                    Scroll
                                </div>
                            </div>
                        </div>
                        :
                        <div className="content__textImage-img">
                            <img src={img.url} alt={img.alt}/>
                        </div>
                    }
                </div>
            </GlobalContainer>
        </div>

    )
}

export default TextImage