import React, { useState, useRef, useEffect } from "react"

import './index.scss';



function Eye({cursorText, showreelUrl}) {
    const eye = useRef();
    const cursor = useRef();
    const [videoOpen, setVideoOpen] = useState(false);

    useEffect(
        () => {
            function eyeMove(e) {
                let x = (eye.current.getBoundingClientRect().left) + (eye.current.clientWidth / 2);
                let y = (eye.current.getBoundingClientRect().top) + (eye.current.clientHeight / 2);

                let distanceX = e.clientX - x;
                let distanceY = e.clientY - y;
                let distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

                if (distance > 40) {
                    let rad = Math.atan2(distanceX, distanceY) + Math.PI * 0.25;
                    let rot = (rad * (180 / Math.PI) * -1) + 180;
                    eye.current.style.transform = `rotate(${rot}deg)`
                    cursor.current.setAttribute("style", "display:none;")
                } else {
                    if (document.querySelector('.appear')) {
                        cursor.current.setAttribute("style", "display:block; top: " + (e.clientY) + "px; left: " + (e.clientX - 150) + "px;")
                    }
                }
            }

            function eyeAppear() {
                const containerTitle = document.querySelector('.agence-title__container');
                const containerTitlePosition = containerTitle.getBoundingClientRect().top;
                const containerTitleHeight = containerTitle.clientHeight;

                const containerEye = document.querySelector('.eye__container');
                const containerEyePosition = containerEye.getBoundingClientRect().top;
                const containerEyeHeight = containerTitle.clientHeight;

                if (containerTitlePosition + containerTitleHeight < containerEyePosition) {
                    containerEye.classList.add('appear')
                } else if (containerTitlePosition > containerEyePosition + containerEyeHeight) {
                    containerEye.classList.add('appear')
                } else {
                    containerEye.classList.remove('appear')
                    cursor.current.setAttribute("style", "display:none;")
                }


            }

            if (eye.current) {
                document.addEventListener('mousemove', eyeMove)
                window.addEventListener('scroll', eyeAppear)
            }
            return () => {
                document.removeEventListener('mousemove', eyeMove);
                window.removeEventListener('scroll', eyeAppear);
            }
        }, []
    );

    return (
        <>
            <div className="move-area">
                <div className="eye__container">
                    <div ref={eye} className="eye" onClick={()=>setVideoOpen(true)}>
                        <div className="pupil"></div>
                    </div>
                </div>
                <div ref={cursor} className="cursor">
                    <p className="cursor-text">{cursorText}</p>
                </div>
            </div>

            <div className="eye-mobile" onClick={()=>setVideoOpen(true)}></div>

            {
                videoOpen && 
                <div className="showreel__container">
                    <div className="showreel-close" onClick={()=>setVideoOpen(false)}></div>
                    <video className="showreel-video" controls autoPlay>
                        <source src={showreelUrl} type="video/mp4"/>
                    </video>
                </div>
            }
        </>
    )
}

export default Eye
