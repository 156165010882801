import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

function GlobalContainer(props) {
  return (
    <div
      className={classNames("global-container", props.className)}
      data-max={props.max}
    >
      {props.children}
    </div>
  );
}

GlobalContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default GlobalContainer;
