import { useEffect, useState } from "react";
import createStatefullContext from "../../utils/createStatefullContext";
import "./index.scss";

const NavbarContext = createStatefullContext(() => {
  const [theme, setTheme] = useState("light");
  return { theme, setTheme };
});

export default NavbarContext;
