import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../Button";
import Columns from "../Columns";
import Effect from "../Effect";
import HtmlRenderer from "../HtmlRenderer";
import "./index.scss";
import useApi from "../../utils/useApi";

export default function Footer(props) {
  let [data, isLoaded] = useApi({ name: "global" });
  if (!isLoaded) return null;
  return <FooterSection data={data} {...props} />;
}

function FooterSection(props) {
  const { mode = "unfolded" } = props;
  const globalStore = useSelector((state) => state.global);
  const { addresses } = globalStore.global;
  const { text, ctaLinks, links } = props.data.footer;

  function renderFodldableZone() {
    if (mode === "folded") return;
    return (
      <div className="foldable">
        <Columns>
          <Columns.Column size="half" className="footer__invite">
            <div className="footer__invite__text">
              <HtmlRenderer
                html={text}
                effect={Effect.Ping.effects["home-bg-black"]}
              />
            </div>
            <div className="footer__invite__links">
              {ctaLinks.map((link, key) => {
                return (
                  <div>
                    <Button href={link.url} theme="light">
                      {link.text}
                    </Button>
                  </div>
                );
              })}
            </div>
          </Columns.Column>
          <Columns.Column size="half">
            <Columns className="footer__adresses">
              {addresses.map((address, key) => {
                return (
                  <Columns.Column
                    key={key}
                    className="adress__container"
                    size="half"
                  >
                    <h3 className="typo--fat">{address.city}</h3>
                    <p>{address.street}</p>
                    <p>{address.zipCode}</p>
                  </Columns.Column>
                );
              })}
            </Columns>
          </Columns.Column>
        </Columns>
      </div>
    );
  }

  return (
    <footer className="footer">
      {renderFodldableZone()}
      <div className="footer__links">
        {links.map((link, key) => {

        	if(link.target){
        		return (
            	<a target={link.target} href={link.url} className="footer__links__link">
								{link.text}
							</a>
          	);
        	} else {
          	return (
            	<Link key={key} className="footer__links__link" to={link.url}>
             		{link.text}
            	</Link>
          	);
          }
        })}
      </div>
    </footer>
  );
}
