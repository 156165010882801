import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import during from "../../utils/during";
import Viewport from "../Viewport";
import { getElementText } from "./utils";

Ping.effects = {
  type: "ping",
  default: {
    base: { background: "black", text: "inherit" },
    appear: { background: "blue", text: "white" },
  },
  "home-bg-black": {
    type: "ping",
    base: { background: "black", text: "inherit" },
    appear: { background: "blue", text: "white" },
  },
  "home-bg-yellow": {
    type: "ping",
    base: { background: "yellow", text: "inherit" },
    appear: { background: "pink", text: "white" },
  },
  "home-bg-blue": {
    type: "ping",
    base: { background: "blue", text: "inherit" },
    appear: { background: "yellow", text: "blue" },
  },
  "home-bg-pink": {
    type: "ping",
    base: { background: "pink", text: "inherit" },
    appear: { background: "yellow", text: "pink" },
  },
  contact: {
    type: "ping",
    base: { background: "blue", text: "white" },
    appear: { background: "pink", text: "yellow" },
  },
  jobs: {
    type: "ping",
    base: { background: "pink", text: "white" },
    appear: { background: "yellow", text: "pink" },
  },
  default_page: {
    type: "ping",
    base: { background: "black", text: "white" },
    appear: { background: "yellow", text: "black" },
  },
  not_found: {
    type: "ping",
    base: { background: "pink", text: "white" },
    appear: { background: "blue", text: "white" },
  },
};

export default function Ping(props) {
  console.log(props);
  const textAnalyseRef = useRef(null);
  const [appeared, setAppeared] = useState(false);
  const [containsCapHeightChars, setContainsCapHeightChars] = useState(false);
  const [containsDescenderChars, setContainsDescenderChars] = useState(false);

  const { base, appear } = props.effect;

  async function runEffect() {
    console.log("RUN EFFECT", props.effect);
    await during(200);
    setAppeared(true);
  }

  function analyseText() {
    const node = textAnalyseRef.current;
    if (!node) return;
    const text = getElementText(node);
    setContainsCapHeightChars(!!text.match(/[A-Z0-9bdfhklti!]/g));
    setContainsDescenderChars(!!text.match(/[gjqpy]/g));
  }

  useEffect(() => {
    analyseText();
  }, []);

  return (
    <Viewport onEnter={runEffect}>
      <span
        className={classNames(
          "effect",
          `effect--type-ping`,
          `effect--cap-height-${containsCapHeightChars ? "yes" : "no"}`,
          `effect--descender-${containsDescenderChars ? "yes" : "no"}`,
          props.className
        )}
      >
        <span ref={textAnalyseRef} className={classNames(`effect__space`)}>
          {props.children}
        </span>
        <span ref={textAnalyseRef} className={classNames(`effect__overlines`)}>
          <span
            className={classNames(
              `effect__first`,
              `effect__base`,
              `effect__overline`,
              `effect__overline--background-${base.background}`,
              `effect__overline--text-${base.text}`
            )}
          >
            <span className="effect__padding"> {props.children}</span>
          </span>
          <span
            className={classNames(
              `effect__overline`,
              `effect__overline--appeared-${appeared ? "yes" : "no"}`,
              `effect__overline--background-${appear.background}`,
              `effect__overline--text-${appear.text}`
            )}
          >
            <span className="effect__padding"> {props.children}</span>
          </span>
        </span>
      </span>
    </Viewport>
  );
}
