import classNames from "classnames";
import { chunk, first } from "lodash";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Appear from "../../components/Appear";
import Button from "../../components/Button";
import Effect from "../../components/Effect";
import Footer from "../../components/Footer";
import HtmlRenderer from "../../components/HtmlRenderer";
import Loader from "../../components/Loader";
import Metatags from "../../components/MetasTags";
import NavbarContext from "../../components/Navbar/NavbarContext";
import ScrollDown from "../../components/ScrollDown";
import Theme from "../../components/Theme";
import GlobalContainer from "../../containers/GlobalContainer";
import { updateGlobalStorePage } from "../../store/action";
import useApi from "../../utils/useApi";
import "./index.scss";

export default function Homepage(props) {
  const { _uid } = props;
  let dispatch = useDispatch();
  const [categorySelectedPosition, setCategorySelectedPosition] = useState(0);
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-home"));
  }, [dispatch]);

  const Navbar = NavbarContext.useContext();

  useEffect(() => {
    Navbar.setTheme("light");
  }, []);

  const location = useLocation();
  const [dataFetch, isLoaded] = useApi({
    slug: location.pathname,
    name: "home",
    _uid,
  });

  return (
    <Fragment>
      <Loader loading={!isLoaded} />
      {isLoaded && <HomepageScreen data={dataFetch} />}
    </Fragment>
  );
}

function HomepageScreen(props) {
  const { metas, sections, projects_section } = props.data;

  function renderSections() {
    return sections.map((section, key) => {
      return (
        <Homepage.Section
          key={key}
          first={key === 0}
          theme={section.background}
          mainText={section.main_text}
          sideText={section.secondary_text}
          scrollDown={key <= 2}
        />
      );
    });
  }

  const filters = Object.keys(projects_section.projects);
  const [currentFilter, setCurrentFilter] = useState(first(filters));

  const onClickOnProjects = useCallback((e) => {
    try {
      const section = e.target.getAttribute("data-section");
      if (section) setCurrentFilter(section);
    } catch (err) {}
  }, []);

  function renderProjects() {
    if (!currentFilter) return;
    if (projects_section.disabled) return;

    const { projects, link } = projects_section;

    const filteredProjects = projects[currentFilter];

    const groups = chunk(filteredProjects, 3);

    return (
      <Theme theme="bg_white" className="homepage__projects">
        <h2 className="typo--extra-fat" onClick={onClickOnProjects}>
          <HtmlRenderer html={projects_section.title} />
        </h2>
        <div className="homepage__projects__groups">
          {groups.map((group, i) => {
            const item_1 = group[0] ? (
              <div className="homepage__projects__main-project">
                <Homepage.Project
                  image={group[0].image}
                  logo={group[0].logo}
                  link={group[0].link}
                  buttonLabel={group[0].buttonLabel}
                />
              </div>
            ) : null;

            const item_2 = group[1] ? (
              <div className="homepage__projects__side-project">
                <Homepage.Project
                  image={group[1].image}
                  logo={group[1].logo}
                  link={group[1].link}
                  buttonLabel={group[1].buttonLabel}
                />
              </div>
            ) : null;

            const item_3 = group[2] ? (
              <div className="homepage__projects__side-project">
                <Homepage.Project
                  image={group[2].image}
                  logo={group[2].logo}
                  link={group[2].link}
                  buttonLabel={group[2].buttonLabel}
                />
              </div>
            ) : null;

            return (
              <div
                className={classNames(
                  "homepage__projects__group",
                  i % 2 === 0
                    ? "homepage__projects__group--direction-normal"
                    : "homepage__projects__group--direction-reverse"
                )}
              >
                <div className="homepage__projects__main-project">{item_1}</div>
                <div className="homepage__projects__side-projects">
                  {item_2}
                  {item_3}
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <Button href={link.url}>{link.title}</Button>
        </div>
      </Theme>
    );
  }

  return (
    <>
      <Metatags {...metas} />
      <div className="homepage">
        {renderSections()}
        {renderProjects()}
      </div>
      <Footer />
    </>
  );
}

Homepage.Section = function Section(props) {
  const ref = useRef();

  return (
    <Theme theme={props.theme}>
      <div ref={ref} className={classNames("homepage__section")}>
        <GlobalContainer>
          <div className={classNames("homepage__section__inner")}>
            <Appear
              className="typo--extra-fat"
              html={props.mainText}
              effect={Effect.Ping.effects[`home-${props.theme}`]}
            />
            {props.sideText ? (
              <HtmlRenderer html={props.sideText}></HtmlRenderer>
            ) : null}
            {props.scrollDown ? <ScrollDown containerRef={ref} /> : null}
          </div>
        </GlobalContainer>
      </div>
    </Theme>
  );
};

Homepage.Section.propTypes = {
  first: PropTypes.bool,
  mainText: PropTypes.string,
  sideText: PropTypes.string,
  scrollDown: PropTypes.bool,
  theme: PropTypes.oneOf(["bg-black", "bg-yellow", "bg-blue", "bg-pink"])
    .isRequired,
};

Homepage.Project = function Project(props) {
  const ref = useRef();

  return (
    <div className="homepage__project">
      <Link className={"homepage__project__link"} to={props.link}>
        <RatioImage
          src={props.image}
          className="homepage__project__image-desktop"
          ratio="1:1"
        />
        <RatioImage
          src={props.image}
          className="homepage__project__image-mobile"
          ratio="1:2"
        />
        <div className={"homepage__project__infos"}>
          <img src={props.logo} className={"homepage__project__logo"} />
          <Button theme="light">{props.buttonLabel}</Button>
        </div>
      </Link>
    </div>
  );
};

Homepage.Project.propTypes = {
  image: PropTypes.string,
  logo: PropTypes.string,
  link: PropTypes.string,
  buttonLabel: PropTypes.string,
};

function RatioImage(props) {
  let src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

  if (props.ratio === "1:2")
    src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7cBAAAAC0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=";

  return (
    <img
      className={classNames("square-image", props.className)}
      src={src}
      style={{ backgroundImage: `url(${props.src})` }}
    />
  );
}
