// GLOBAL STATE
export function updateGlobalStoreGlobal(datas){
	return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas){
	return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStorePage(datas){
	return {type: GLOBAL_UPDATE_PAGE, value: datas}
}

export function updateGlobalStoreLang(datas){
	return {type: GLOBAL_UPDATE_LANG, value: datas}
}

export function updateGlobalisLoaded(bool){
	return {type: GLOBAL_IS_LOADED, value: bool}
}

// SCROLL STATE
export function updateGlobalScrollLock(bool){
	return {type: GLOBAL_IS_SCROLLLOCKED, value: bool}
}



export const GLOBAL_UPDATE_GLOBAL  = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES  = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_PAGE  = 'UPDATE_PAGE';
export const GLOBAL_UPDATE_LANG  = 'UPDATE_LANG';
export const GLOBAL_IS_LOADED  = 'IS_LOADED';
export const GLOBAL_IS_SCROLLLOCKED  = 'IS_SCROLLLOCKED';