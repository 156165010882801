import React from "react";
import "./index.scss";

export default function Asset(props) {
  return (
    <img
      className="asset"
      src={props.descriptor.x1}
      srcSet={`${props.descriptor.x1} 1x, ${props.descriptor.x2} 2x, `}
      alt={props.descriptor.alt || ""}
      width={props.descriptor.width}
      height={props.descriptor.height}
    />
  );
}
