import scroll_down_1x from "../assets/scroll_down.png";
import scroll_down_2x from "../assets/scroll_down@2x.png";

import scroll_down_black_1x from "../assets/scroll_down_black.png";
import scroll_down_black_2x from "../assets/scroll_down_black@2x.png";

import logo_desktop_full_white_1x from "../assets/logo_blanc3.png";
import logo_desktop_full_white_2x from "../assets/logo_blanc3.png";

import logo_desktop_compressed_white_1x from "../assets/logo_blanc4.png";
import logo_desktop_compressed_white_2x from "../assets/logo_blanc4.png";

import logo_mobile_compressed_white_1x from "../assets/logo_mobile_blanc4.png";
import logo_mobile_compressed_white_2x from "../assets/logo_mobile_blanc4.png";

import logo_mobile_white_1x from "../assets/logo_mobile_blanc3.png";
import logo_mobile_white_2x from "../assets/logo_mobile_blanc3.png";

import logo_desktop_full_black_1x from "../assets/logo_black3.png";
import logo_desktop_full_black_2x from "../assets/logo_black3.png";

import logo_desktop_compressed_black_1x from "../assets/logo_black4.png";
import logo_desktop_compressed_black_2x from "../assets/logo_black4.png";

import logo_mobile_compressed_black_1x from "../assets/logo_mobile_black4.png";
import logo_mobile_compressed_black_2x from "../assets/logo_mobile_black4.png";

import logo_mobile_black_1x from "../assets/logo_mobile_black3.png";
import logo_mobile_black_2x from "../assets/logo_mobile_black3.png";

const list = {
  scroll_down: {
    x1: scroll_down_1x,
    x2: scroll_down_2x,
    width: 49,
    height: 49,
  },
  scroll_down_black: {
    x1: scroll_down_black_1x,
    x2: scroll_down_black_2x,
    width: 49,
    height: 49,
  },
  logo_desktop_full_white: {
    x1: logo_desktop_full_white_1x,
    x2: logo_desktop_full_white_2x,
    width: 196,
    height: 108,
  },
  logo_desktop_compressed_white: {
    x1: logo_desktop_compressed_white_1x,
    x2: logo_desktop_compressed_white_2x,
    width: 31,
    height: 51,
  },
  logo_mobile_white: {
    x1: logo_mobile_white_1x,
    x2: logo_mobile_white_2x,
    width: 99,
    height: 55,
  },
  logo_mobile_compressed_white: {
    x1: logo_mobile_compressed_white_1x,
    x2: logo_mobile_compressed_white_2x,
    width: 20,
    height: 34,
  },
  logo_desktop_full_black: {
    x1: logo_desktop_full_black_1x,
    x2: logo_desktop_full_black_2x,
    width: 196,
    height: 108,
  },
  logo_desktop_compressed_black: {
    x1: logo_desktop_compressed_black_1x,
    x2: logo_desktop_compressed_black_2x,
    width: 31,
    height: 51,
  },
  logo_mobile_black: {
    x1: logo_mobile_black_1x,
    x2: logo_mobile_black_2x,
    width: 99,
    height: 55,
  },
  logo_mobile_compressed_black: {
    x1: logo_mobile_compressed_black_1x,
    x2: logo_mobile_compressed_black_2x,
    width: 20,
    height: 34,
  },
};

const AssetService = {
  list,
};

export default AssetService;
