import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import during from "../../utils/during";
import Ping from "./Ping";
import { getElementText } from "./utils";
import "./index.scss";

PingPong.effects = {
  default: {
    base: { background: "black", text: "inherit" },
    steps: [{ background: "blue", text: "white", direction: "right" }],
  },
  "home-bg-black": {
    base: { background: "black", text: "inherit" },
    steps: [
      { background: "blue", text: "white", direction: "right" },
      { background: "black", text: "white", direction: "right" },
      { background: "blue", text: "white", direction: "left" },
    ],
  },
  // "home-bg-black": {
  //   base: { background: "black", text: "inherit" },
  //   steps: [
  //     { background: "yellow", text: "black", direction: "right" },
  //     { background: "blue", text: "white", direction: "left" },
  //   ],
  // },
  // "home-bg-black": {
  //   base: { background: "black", text: "inherit" },
  //   steps: [
  //     { background: "blue", text: "blue", direction: "right" },
  //     { background: "blue", text: "white", direction: "left" },
  //   ],
  // },
  "home-bg-yellow": {
    base: { background: "yellow", text: "inherit" },
    steps: [
      { background: "pink", text: "white", direction: "right" },
      { background: "yellow", yellow: "inherit", direction: "right" },
      { background: "pink", text: "white", direction: "left" },
    ],
  },
  "home-bg-blue": {
    base: { background: "blue", text: "inherit" },
    steps: [
      { background: "yellow", text: "blue", direction: "right" },
      { background: "blue", text: "inherit", direction: "right" },
      { background: "yellow", text: "blue", direction: "left" },
    ],
  },
  "home-bg-pink": {
    base: { background: "pink", text: "inherit" },
    steps: [
      { background: "yellow", text: "pink", direction: "right" },
      { background: "pink", text: "inherit", direction: "right" },
      { background: "yellow", text: "pink", direction: "left" },
    ],
  },
  contact: {
    base: { background: "blue", text: "white" },
    steps: [
      { background: "pink", text: "yellow", direction: "right" },
      { background: "blue", text: "white", direction: "right" },
      { background: "pink", text: "yellow", direction: "left" },
    ],
  },
  jobs: {
    base: { background: "pink", text: "white" },
    steps: [
      { background: "yellow", text: "pink", direction: "right" },
      { background: "pink", text: "white", direction: "right" },
      { background: "yellow", text: "pink", direction: "left" },
    ],
  },
};

function PingPong(props) {
  const textAnalyseRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [containsCapHeightChars, setContainsCapHeightChars] = useState(false);
  const [containsDescenderChars, setContainsDescenderChars] = useState(false);

  const { base } = props.effect;
  const steps = useMemo(() => [...props.effect.steps], [props.effect.steps]);

  async function runEffect() {
    await during(200);
    for (let i = 0; i < steps.length; i++) {
      setCurrentStep(i);
      await during(1000);
    }
  }

  function analyseText() {
    const node = textAnalyseRef.current;
    if (!node) return;
    const text = getElementText(node);
    setContainsCapHeightChars(!!text.match(/[A-Z0-9bdfhklti!]/g));
    setContainsDescenderChars(!!text.match(/[gjqpy]/g));
  }

  useEffect(() => {
    runEffect();
    analyseText();
  }, []);

  return (
    <span
      className={classNames(
        "effect",
        `effect--type-pingpong`,
        `effect--cap-height-${containsCapHeightChars ? "yes" : "no"}`,
        `effect--descender-${containsDescenderChars ? "yes" : "no"}`,
        props.className
      )}
    >
      <span ref={textAnalyseRef} className={classNames(`effect__space`)}>
        {props.children}
      </span>
      <span ref={textAnalyseRef} className={classNames(`effect__overlines`)}>
        <span
          className={classNames(
            `effect__first`,
            `effect__base`,
            `effect__overline`,
            `effect__overline--background-${base.background}`,
            `effect__overline--text-${base.text}`
          )}
        >
          <span className="effect__padding"> {props.children}</span>
        </span>
        {steps.map((step, i) => (
          <span
            key={i}
            className={classNames(
              `effect__overline`,
              `effect__overline--background-${step.background}`,
              `effect__overline--text-${step.text}`,
              `effect__overline--direction-${step.direction}`,
              i > currentStep || currentStep === null
                ? `effect__overline--progression-idling`
                : i === currentStep
                ? `effect__overline--progression-running`
                : `effect__overline--progression-ended`
            )}
          >
            <span className="effect__padding"> {props.children}</span>
          </span>
        ))}
      </span>
    </span>
  );
}

Selected.effects = {
  default: { background: "black", text: "white" },
  blue: { background: "blue", text: "white" },
};

function Selected(props) {
  const textAnalyseRef = useRef(null);
  const [containsCapHeightChars, setContainsCapHeightChars] = useState(false);
  const [containsDescenderChars, setContainsDescenderChars] = useState(false);

  const { background, text } = props.effect;

  function analyseText() {
    const node = textAnalyseRef.current;
    if (!node) return;
    const text = getElementText(node);
    setContainsCapHeightChars(!!text.match(/[A-Z0-9bdfhklti!]/g));
    setContainsDescenderChars(!!text.match(/[gjqpy]/g));
  }

  useEffect(() => {
    analyseText();
  }, []);

  return (
    <span
      className={classNames(
        `effect`,
        `effect--cap-height-${containsCapHeightChars ? "yes" : "no"}`,
        `effect--descender-${containsDescenderChars ? "yes" : "no"}`,
        `effect--type-selected`,
        props.className
      )}
    >
      <span ref={textAnalyseRef} className={classNames(`effect__space`)}>
        {props.children}
      </span>
      <span ref={textAnalyseRef} className={classNames(`effect__overlines`)}>
        <span
          className={classNames(
            `effect__overline`,
            `effect__overline--background-${background}`,
            `effect__overline--text-${text}`
          )}
        >
          <span className="effect__padding">{props.children}</span>
        </span>
      </span>
    </span>
  );
}

function RollOn(props) {
  return (
    <span
      className={classNames(
        `roll-on`,
        `roll-on--trigger-${props.trigger}`,
        `roll-on--background-${props.background}`,
        `roll-on--text-${props.text}`,
        props.className
      )}
    >
      <span className="roll-on__real">{props.children}</span>
      <span className="roll-on__effect">
        <span className="roll-on__effect__inner">{props.children}</span>
      </span>
    </span>
  );
}

function Color(props) {
  return (
    <span
      className={classNames(
        `color`,
        `color--text-${props.text}`,
        props.className
      )}
    >
      {props.children}
    </span>
  );
}

function Underline(props) {
  return (
    <span className={classNames(`underline`, props.className)}>
      {props.children}
    </span>
  );
}

const Effect = {
  Ping,
  PingPong,
  Selected,
  RollOn,
  Color,
  Underline,
};

export default Effect;
