import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../../store/action";
import useApi from "../../utils/useApi";
import "./index.scss";

// COMPONENTS
import Loader from "../../components/Loader";
import Metatags from "../../components/MetasTags";
import ExpertisesDetails from "../../components/ExpertisesDetails";
import ExpertisesRedirect from "../../components/ExpertisesRedirect";

const Expertises = ({ _uid, slug }) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("single-expertises"));
  }, [dispatch]);

  const [dataFetch, isLoaded] = useApi({
    slug: slug,
    name: "expertises/single",
    _uid,
  });
  const { color, menuBlock, metas, subMenu, title } = dataFetch;

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...metas} />
          <ExpertisesDetails
            title={title}
            extraClass={color}
            itemsMenu={subMenu.items}
          />
          <ExpertisesRedirect
            title={menuBlock.title}
            extraClass={menuBlock.color}
            listItemsMenu={menuBlock.items}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Expertises;
