import classNames from "classnames";
import { fill, isNumber, compact } from "lodash";
import React, {
  createContext,
  Fragment,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import during from "../../utils/during";
import Theme from "../Theme";
import "./index.scss";
import Typist from "react-typist";
import HtmlRenderer from "../HtmlRenderer";
import Viewport from "../Viewport";

const AppearContext = createContext(true);

const intersperse = (arr, sep) =>
  arr.reduce((a, v) => [...a, v, sep], []).slice(0, -1);

export default function Appear(props) {
  const [beenInViewport, setBeenInViewPort] = useState(false);
  const [appeared, setAppeared] = useState(false);

  const text = useMemo(() => {
    const div = document.createElement("div");
    div.innerHTML = props.html;
    return intersperse(compact(div.textContent.split("\n")), <br />);
  }, [props.html]);

  let children;

  if (!beenInViewport) {
    children = (
      <div className="appear">
        <div className="appear__space">{text}</div>
      </div>
    );
  } else {
    if (!appeared) {
      children = (
        <div className="appear">
          <div className="appear__space">{text}</div>
          <div className="appear__typing">
            <Typist onTypingDone={() => setAppeared(true)} avgTypingDelay={40}>
              {text}
            </Typist>
          </div>
        </div>
      );
    } else {
      children = (
        <HtmlRenderer html={props.html} effect={props.effect}></HtmlRenderer>
      );
    }
  }

  return (
    <div className={classNames("typing", props.className)}>
      <Viewport onEnter={() => setBeenInViewPort(true)}>{children}</Viewport>
    </div>
  );
}

Appear.Context = AppearContext;

function TypingAnimation(props) {
  console.log("CHILDREN", props.children);
  return <div className={classNames("typing", props.className)}></div>;
}

function AppearOld(props) {
  const theme = useContext(Theme.Context);
  const container = useRef();
  const delay = props.delay || 0;
  const duration = props.duration || 1200;
  const delay_after = props.delay || 0;

  const [lineHeight, setLineHeight] = useState(null);
  const [numberOfLines, setNumberOfLines] = useState(null);
  const [currentlyAnimatedLine, setCurrentyAnimatedLine] = useState(null);

  async function reveal() {
    if (!container.current) return;
    const height = container.current.getBoundingClientRect().height;
    const lineHeightStr = document.defaultView.getComputedStyle(
      container.current,
      null
    ).lineHeight;
    console.log(height, lineHeightStr);
    const numberOfLines = Math.round(height / parseInt(lineHeightStr));
    setNumberOfLines(numberOfLines);
    setLineHeight(parseFloat(lineHeightStr));
    await during(10);
    setTimeout(async () => {
      for (let i = 0; i <= numberOfLines; i++) {
        setCurrentyAnimatedLine(i);
        await during(delay + duration + delay_after);
      }
    }, 10);
  }

  useLayoutEffect(() => {
    reveal();
  }, []);

  function renderMask(i) {
    let state;
    if (currentlyAnimatedLine === null) state = "masking";
    else if (currentlyAnimatedLine < i) state = "masking";
    else if (currentlyAnimatedLine === i) state = "revealing";
    else state = "revealed";

    return (
      <div
        key={i}
        className={classNames(
          "appear__mask",
          `appear__mask--cursor-${props.cursor ? "yes" : "no"}`,
          `appear__mask--state-${state}`,
          `appear__mask--theme-${theme}`
        )}
        style={{
          top: i * lineHeight,
          height: lineHeight,
          transitionDelay: `${delay}ms`,
          transitionDuration: `${duration}ms`,
        }}
      ></div>
    );
  }

  function renderMasks() {
    if (!isNumber(lineHeight)) return;
    if (!isNumber(numberOfLines)) return;
    return (
      <Fragment>
        {fill(Array(numberOfLines), 0).map((_, i) => renderMask(i))}
      </Fragment>
    );
  }

  return (
    <div className={classNames(props.className, "appear")} ref={container}>
      {props.children}
      {renderMasks()}
    </div>
  );
}
