import React, { useEffect, useRef, useState } from "react";

export default function Viewport(props) {
  const ref = useRef();

  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (triggered) return;

    function onScroll() {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      ) {
        props.onEnter();
        setTriggered(true);
      }
    }
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [triggered]);

  return <div ref={ref}>{props.children}</div>;
}
