import React, { ReactNode, useContext } from "react";

/*


*/

const createStatefullContext = (hook, options = {}) => {
  const context = React.createContext(options.initialValue);

  function Provider(props) {
    const value = hook(props.params);
    let node = options.renderProvider
      ? options.renderProvider(value, props.children)
      : props.children;
    return <context.Provider value={value}>{node}</context.Provider>;
  }

  function useStatefullContext() {
    if (context === undefined) throw new Error("Not provided");
    return useContext(context);
  }

  return { Provider, useContext: useStatefullContext, context: context };
};

export default createStatefullContext;

/*

const createStatefullContext = <TContextValue, TParams = null>(
  hook: (params: TParams) => TContextValue,
  options: {
    initialValue?: TContextValue;
    renderProvider?: (
      value: TContextValue,
      children: ReactNode
    ) => React.ReactElement;
  } = {}
) => {
  const context = React.createContext<TContextValue | undefined>(
    options.initialValue
  );

  function Provider(props: { children: ReactNode; params: TParams }) {
    const value = hook(props.params);
    let node = options.renderProvider
      ? options.renderProvider(value, props.children)
      : props.children;
    return <context.Provider value={value}>{node}</context.Provider>;
  }

  function useStatefullContext() {
    if (context === undefined) throw new Error("Not provided");
    return useContext(context) as TContextValue;
  }

  return [useStatefullContext, Provider, context] as const;
};

export default createStatefullContext;

*/
