import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGlobalStorePage } from "../../store/action";
import { useLocation } from "react-router-dom";
import useApi from "../../utils/useApi";
import "./index.scss";
import Navbar from "../../components/Navbar";
import Loader from "../../components/Loader";
import Theme from "../../components/Theme";
import Effect from "../../components/Effect";
import Footer from "../../components/Footer";
import Metatags from "../../components/MetasTags";
import GlobalContainer from "../../containers/GlobalContainer/index";

export default function Default({ _uid }) {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-defaut"));
  }, [dispatch]);

  const location = useLocation();
  const [dataFetch, isLoaded] = useApi({
    slug: location.pathname,
    name: "page",
    _uid,
  });

  const globalStore = useSelector((state) => state.global);

  return (
    <Fragment>
      <Loader loading={!isLoaded} />
      {isLoaded && <DefaultScreen data={dataFetch} />}
    </Fragment>
  );
}

function DefaultScreen(props) {
  const { metas, title, content } = props.data;

  return (
    <>
      <Metatags {...metas} />
      <Theme theme="bg-black">
        <Navbar.Spacer />
        <GlobalContainer>
          <div className="tpl-default__container">
            <h1>
              <Effect.Ping effect={Effect.Ping.effects.default_page}>
                {title}
              </Effect.Ping>
            </h1>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </GlobalContainer>
        <Footer mode="folded" />
      </Theme>
    </>
  );
}
