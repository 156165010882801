import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import Metatags from "../../components/MetasTags";
import GlobalContainer from "../../containers/GlobalContainer/index";
import { updateGlobalStorePage } from "../../store/action";
import useApi from "../../utils/useApi";
import "./index.scss";
import Theme from "../../components/Theme";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Appear from "../../components/Appear";
import Effect from "../../components/Effect";

const Jobs = ({ _uid }) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-jobs"));
  }, [dispatch]);

  const location = useLocation();
  const [dataFetch, isLoaded] = useApi({
    slug: location.pathname,
    name: "page",
    _uid,
  });

  return (
    <Fragment>
      <Loader loading={!isLoaded} />
      {isLoaded && <JobsScreen data={dataFetch} />}
    </Fragment>
  );
};

function JobsScreen(props) {
  const { metas, title, mail } = props.data;

  return (
    <Fragment>
      <Metatags {...metas} />
      <Theme theme="bg-pink">
        <Navbar.Spacer />
        <GlobalContainer className="jobs">
          <div className="jobs__content">
            <div className="typo--extra-fat">
              <Appear html={title} effect={Effect.Ping.effects.jobs}></Appear>
            </div>
            <div>
              <a className="jobs__email-link typo--fat" href={`mailto:${mail}`}>
                {mail}
              </a>
            </div>
          </div>
        </GlobalContainer>
      </Theme>
      <Footer mode="unfolded" />
    </Fragment>
  );
}

export default Jobs;
