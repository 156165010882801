import React, { Fragment } from "react";
import Desktop from "./Desktop";
import "./index.scss";
import Mobile from "./Mobile";

export default function Navbar(props) {
  return (
    <Fragment>
      <Desktop />
      <Mobile />
    </Fragment>
  );
}

Navbar.Spacer = function Spacer() {
  return <div className="navbar__spacer" />;
};
