import React from "react"
import './index.scss';

import { Link } from "react-router-dom"

const ImageOverlay = ({ title, url, percentSize, textHover, imgSrc, overlayColor }) => {

    const onMouseMove = (e) => {
        const cursor = e.target.querySelector(".cursor");
        if (cursor != null) {
            const infos = e.target.getBoundingClientRect();
            cursor.setAttribute("style", "top: " + (e.clientY - infos.top ) + "px; left: " + (e.clientX - infos.left) + "px;")
        }
    }

    return (
        <Link to={url} className="image-overlay__wrapper" onMouseMove={onMouseMove} style={{ width: `${percentSize}%` }}>
            <div className={`image-overlay__container`}>
                <img src={imgSrc} alt={textHover} />
                <h2 className={`text-hover ${percentSize < 30 ? 'small-text' : ''}`}>{textHover}</h2>
                <div className={`overlay ${overlayColor === "purple" ? "purple" : "green"}`}></div>
                <div className="cursor">
                    {/* //Changement d'icone : je met en commentaire a date, pasque bon... <p className="cursor-text">Voir le cas</p> */}
                    <div className="eye"></div>
                </div>
            </div>
            <h3 className="title">{title}</h3>
        </Link>
    )
}

export default ImageOverlay
