import React from "react"


import ReactPlayer from 'react-player'
//const Promise = require('es6-promise').Promise;
import { ReactComponent as Play } from '../../../img/play.svg';

import './index.scss';
import GlobalContainer from "../../../containers/GlobalContainer";


const Video = ({ src, thumbnail, type }) => {
    return (

        <div className="content__video">
            <GlobalContainer max={true}>
                <div className="content__video-inner">
                    {

                        src.toLowerCase().indexOf("vimeo.com") > -1 ?
                            <div className="content__video-inner-vimeo">
                                <div class="vimeo_player" videoID={src} width="100%" height="100%"></div>
                                {/* <iframe src={src} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> */}
                            </div>
                            :
                            <ReactPlayer
                                className="content__video-player"
                                width="100%"
                                height="100%"
                                url={[
                                    { src: src, type: 'video/mp4' },
                                ]}
                                light={thumbnail}
                                playing
                                controls
                                playIcon={<Play />}
                            />
                    }

                </div>
            </GlobalContainer>
        </div>
    )
}

export default Video
