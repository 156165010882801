import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as serviceWorker from "./serviceWorker";

// Redux
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { globalReducer } from "./store/Reducers/globalReducer";
import { pageReducer } from "./store/Reducers/pageReducer";
import AppMatomo from "./components/AppMatomo";

const reducer = combineReducers({
  global: globalReducer,
  page: pageReducer,
});

const store = createStore(reducer);



ReactDOM.render(
  <Provider store={store}>
    <AppMatomo></AppMatomo>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
