import React from "react"
import './index.scss';
import GlobalContainer from "../../../containers/GlobalContainer";

const Images = ({images}) => {
    return (
        <div className="content__images">
            <GlobalContainer max={true}>
                <div className="content__images-inner">
                    {
                        images.map((img, key) => {
                            return (
                                <div key={key} className="content__images-img">
                                    <img src={img.url} alt={img.alt}/>
                                </div>
                            )
                        })
                    }
                </div>
            </GlobalContainer>
        </div>
    )
}

export default Images