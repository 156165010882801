import classNames from "classnames";
import parse, { domToReact } from "html-react-parser";
import { includes } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import Effect from "../../components/Effect";
import Theme from "../../components/Theme";
import "./index.scss";
import { ReactSVG } from "react-svg";
import reactStringReplace from "react-string-replace";
import pacha from "../../assets/pacha.svg";
import la_factory from "../../assets/la_factory.svg";

/*
Wrapper to handle HTML handling, in the future
*/

export default function HtmlRenderer(props) {
  function replace(node) {
    try {
      if (
        props.effect &&
        node.type === "tag" &&
        (node.name === "a" || node.name === "strong")
      ) {
        return (
          <node.name {...node.attribs}>
            <Effect.Ping effect={props.effect} className={props.className}>
              {domToReact(node.children, { replace })}
            </Effect.Ping>
          </node.name>
        );
      } else if (node.type === "text") {
        const pachaReplaced = reactStringReplace(
          node.data,
          "PACHA",
          (match) => {
            return (
              <ReactSVG
                className="pacha-svg"
                src={pacha}
                wrapper="span"
                className="pacha-svg"
                alt="PACHA"
              />
            );
          }
        );
        const laFactoryReplaced = reactStringReplace(
          pachaReplaced,
          "LaFactory",
          (match) => {
            return (
              <ReactSVG
                className="lafactory-svg"
                src={la_factory}
                wrapper="span"
                className="lafactory-svg"
                title="LaFactory"
              />
            );
          }
        );
        return <Fragment>{laFactoryReplaced}</Fragment>;
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }

  const children = parse(props.html || "", { replace });

  return (
    <div className={classNames("html-renderer", props.className)}>
      {children}
    </div>
  );
}

HtmlRenderer.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string,
};
