import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import RouteForTemplates from "./utils/routes-for-templates";

import useApi from "./utils/useApi";
import { useSelector, useDispatch } from "react-redux";
import {
  updateGlobalStoreGlobal,
  updateGlobalisLoaded,
  updateGlobalStoreRoutes,
} from "./store/action";

import "./style/base/index.scss";

// COMPONENTS
import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import NavbarContext from "./components/Navbar/NavbarContext";
// import NavbarMobile from "./components/NavbarMobileOld/index";
import Popin from "./components/Popin";

function App() {
  // Global apis
  let dispatch = useDispatch();
  let globalStore = useSelector((state) => state.global);
  let [dataGlobalAPI, dataGlobalIsLoaded] = useApi({ name: "global" });
  let [dataRoutesAPI, routesIsLoaded] = useApi({ name: "routes" });

  let { routes, isLoaded } = globalStore;
  let dataRoutes = routes;
  //console.log(dataGlobalAPI);
  //
  let page = useSelector((state) => state.page).page;

  // STORE ADD GLOBAL API
  useEffect(() => {
    dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
  }, [dispatch, dataGlobalAPI]);
  // STORE ADD ROUTES API
  useEffect(() => {
    dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
  }, [dispatch, dataRoutesAPI]);

  // STORE SET IS LOADED
  useEffect(() => {
    dispatch(updateGlobalisLoaded(routesIsLoaded && dataGlobalIsLoaded));
  }, [dispatch, routesIsLoaded, dataGlobalIsLoaded]);

  /*const [isMobile, setIsMobile] = useState();

    const getDevice = useCallback(() => {
        if (window.innerWidth < 992) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        return isMobile
    }, [isMobile])


    useEffect(() => {

        // did mount
        getDevice()
        window.addEventListener('resize', getDevice)

        return () => {
            // will unmoumnt
            window.removeEventListener('resize', getDevice)
        }
    }, [getDevice])*/

  const [firstVisit, setFirstVisit] = useState(false);

  useEffect(() => {
    if (document.cookie.indexOf("visited=true") === -1) {
      setFirstVisit(true);
      document.cookie = "visited=true";
    }
  }, []);

  return (
    <NavbarContext.Provider>
      <div className={`app ${page}`}>
        <Loader loading={!isLoaded} />
        <Router>
          {isLoaded ? (
            <>
              <ScrollToTop>
                {/*{isMobile ? <NavbarMobile/> : <Navbar/>}*/}
                <Navbar />
                <Switch>
                  {dataRoutes &&
                    dataRoutes.map((route) => {
                      return (
                        <RouteForTemplates
                          key={route._uid}
                          {...route}
                          datas={{}}
                        />
                      );
                    })}
                </Switch>
                <Popin openDefault={firstVisit}>
                  <p className="popin-texte">
                    Havas Factory a désormais rejoint le réseau{" "}
                    <span className="red">Prose On Pixels</span>
                  </p>
                  <a
                    className="popin-link"
                    href="https://www.proseonpixels.com/"
                    target="_blank"
                  >
                    Découvrir
                  </a>
                </Popin>
              </ScrollToTop>
            </>
          ) : (
            <div style={{ height: "100vh" }}></div>
          )}
        </Router>
      </div>
    </NavbarContext.Provider>
  );
}

export default App;
