import React from "react"
import './index.scss';

import GlobalContainer from '../../containers/GlobalContainer/index'

function Banner({ subject, title, backgroundVideo, srcVideo, srcPhoto }) {
    return (

        <div className={subject ? 'banner__container banner__container--project' : 'banner__container'}>
            <GlobalContainer>
                <div className="banner-content">
                    {subject && <div className="banner-overtitle">{subject}</div>}
                    {title && <h1 className="title">{title}</h1>}
                </div>
            </GlobalContainer>
            <div className="banner-overlay"></div>

            {backgroundVideo ?
                <video className="background-video" loop muted autoPlay>
                    <source src={srcVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> :
                <div className="background-photo" style={{ backgroundImage: `url(${srcPhoto})` }}></div>}

            <p className="banner-scroll">Scroll</p>
        </div>

    )
}

export default Banner
