import React, { useCallback, useContext } from "react";
import Asset from "../../components/Asset";
import Theme from "../../components/Theme";
import AssetService from "../../services/AssetService";
import "./index.scss";

export default function ScrollDown(props) {
  const theme = useContext(Theme.Context);

  const onClickScrollDown = useCallback(() => {
    if (!props.containerRef.current) return;
    window.scrollBy({
      top: props.containerRef.current.getBoundingClientRect().height,
      left: 0,
      behavior: "smooth",
    });
  });

  const asset =
    theme === "bg-yellow" || theme === "bg-white"
      ? AssetService.list.scroll_down_black
      : AssetService.list.scroll_down;

  return (
    <div className="scroll-down">
      <button className="scroll-down__button" onClick={onClickScrollDown}>
        <Asset descriptor={asset} />
      </button>
    </div>
  );
}
