import React, { useState, useEffect } from "react"
import './index.scss';

// COMPONENTS
import MenuBubble from '../../components/MenuBubble';
import ImageOverlay from '../../components/ImageOverlay';

// CONTAINER
import GlobalContainer from '../../containers/GlobalContainer/index';


const ExpertisesDetails = ({ itemsMenu, title, extraClass }) => {
    const currentCategory = itemsMenu.findIndex(x => x.selected === true);
    const [categorySelectedPosition, setCategorySelectedPosition] = useState(currentCategory);
    const [expanded, setExpanded] = useState(false)

    const toggleExpand = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        setExpanded(false);
        renderProjects();
    }, [categorySelectedPosition])

    const hasTextMore = !!itemsMenu[categorySelectedPosition].textMore;

    function renderProjects() {
        const currentProjects = itemsMenu[categorySelectedPosition].projects;
        const groupSize = 2;
        let rows = currentProjects.map(function (project, index) {
            // map content to html elements

            return (
                <ImageOverlay key={index}
                    title={project.title}
                    url={project.url}
                    textHover={project.titleHover}
                    imgSrc={project.image}
                    overlayColor={extraClass}
                />
            )
        }).reduce(function (r, element, index) {
            // create element groups with size 3, result looks like:
            // [[elem1, elem2, elem3], [elem4, elem5, elem6], ...]
            index % groupSize === 0 && r.push([]);
            r[r.length - 1].push(element);
            return r;
        }, []).map(function (rowContent, key) {
            // surround every group with 'row'
            return <div className="two-visuals__container" key={key}>{rowContent}</div>;
        });
        return <div className="expertises-details__projects">{rows}</div>;
    }

    return (

        <div className="expertises-details__global">
            <div className={`expertises-details__container ${extraClass}`}>
                <GlobalContainer>
                    <h2 className="title">{title}</h2>
                    <MenuBubble getClickedKey={setCategorySelectedPosition}
                        itemsMenu={itemsMenu} indexSelected={categorySelectedPosition} pageExpertises={true} />
                    {
                        itemsMenu[categorySelectedPosition].subTitle &&
                        <div className="expertises-details__content">
                            {/* <h3 className="subtitle">{itemsMenu[categorySelectedPosition].subTitle}</h3> */}
                            <p className="text"
                                dangerouslySetInnerHTML={{ __html: itemsMenu[categorySelectedPosition].text }}></p>
                            {/* {!expanded && hasTextMore &&
                                <span className="dots">...</span>
                            } */}
                            {hasTextMore && expanded &&
                                <p className="text more"
                                    dangerouslySetInnerHTML={{ __html: itemsMenu[categorySelectedPosition].textMore }}></p>
                            }
                            {hasTextMore &&
                                <div className="read-more__container">
                                    <button onClick={toggleExpand}
                                        className={`read-more ${expanded ? 'closed' : ''}`}></button>
                                </div>
                            }
                        </div>
                    }
                </GlobalContainer>
            </div>
            {renderProjects()}
        </div>
    )
};


export default ExpertisesDetails;