import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Asset from "../../components/Asset";
import GlobalContainer from "../../containers/GlobalContainer/index";
import AssetService from "../../services/AssetService";
import BurgerIcon from "../Burger";
import "./index.scss";
import NavbarContext from "./NavbarContext";
import Effect from "../../components/Effect";

export default function Desktop() {
  const { theme } = NavbarContext.useContext();

  const globalStore = useSelector((state) => state.global);
  const { logo, links } = globalStore.global.header;

  const logoSrc =
    theme === "light"
      ? AssetService.list.logo_desktop_full_white
      : AssetService.list.logo_desktop_full_black;
  const logoSmallSrc =
    theme === "light"
      ? AssetService.list.logo_desktop_compressed_white
      : AssetService.list.logo_desktop_compressed_black;

  const [bigLogoVisible, setBigLogoVisible] = useState(true);
  const [smallLogoVisible, setSmallLogoVisible] = useState(false);
  const [itemsVisible, setItemsVisible] = useState(true);
  const [burgerToggleVisible, setBurgerToggleVisible] = useState(false);
  const [menuForced, setMenuForced] = useState(false);

  useEffect(() => {
    function onScroll() {
      if (window.pageYOffset >= 1) {
        setBigLogoVisible(false);
        setSmallLogoVisible(true);
        setItemsVisible(false);
        setBurgerToggleVisible(true);
        setMenuForced(false);
      } else {
        setBigLogoVisible(true);
        setSmallLogoVisible(false);
        setItemsVisible(true);
        setBurgerToggleVisible(false);
        setMenuForced(false);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onMenuToggle = useCallback((forced) => {
    if (forced) {
      setItemsVisible(true);
      setMenuForced(true);
    } else {
      setItemsVisible(false);
      setMenuForced(false);
    }
  });

  return (
    <div
      className={classNames("navbar-desktop", `navbar-desktop--theme-${theme}`)}
    >
      <div className={classNames("navbar-desktop__inner")}>
        <div className={classNames("navbar-desktop__bar")}>
          <Link
            className={classNames(
              `navbar-desktop__big-logo`,
              `navbar-desktop__big-logo--visible-${
                bigLogoVisible ? "yes" : "no"
              }`
            )}
            to={logo.url}
          >
            <Asset descriptor={logoSrc} />
          </Link>
          <div className="navbar-desktop__menu">
            <ul
              className={classNames(
                `navbar-desktop__items`,
                `navbar-desktop__items--visible-${itemsVisible ? "yes" : "no"}`
              )}
            >
              {links.map((link, key) => {
                return (
                  <li key={key} className={"navbar-desktop__item"}>
                    <Effect.Underline>
                      <Link to={link.url} className="navbar-desktop__link">
                        {link.text}
                      </Link>
                    </Effect.Underline>
                  </li>
                );
              })}
            </ul>
            <div
              className={classNames(
                `navbar-desktop__burger-toggle`,
                `navbar-desktop__burger-toggle--visible-${
                  burgerToggleVisible ? "yes" : "no"
                }`
              )}
            >
              <BurgerIcon toggled={menuForced} toggle={onMenuToggle} />
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "navbar-desktop__small-logo",
            `navbar-desktop__small-logo--visible-${
              smallLogoVisible ? "yes" : "no"
            }`
          )}
        >
          <Asset descriptor={logoSmallSrc} />
        </div>
      </div>
    </div>
  );
}
