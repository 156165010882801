import React from "react";
// import React, {useEffect} from "react";
import { Route } from 'react-router-dom';
// import {Route, useHistory, useLocation} from 'react-router-dom';

// templates
import Homepage from "../pages/Homepage";
import Contact from "../pages/Contact";
import Agence from "../pages/Agence";
import Works from "../pages/Works";
import Jobs from "../pages/Jobs";
import Cas from "../pages/Cas";
import Expertises from "../pages/Expertises";
import NotFound from "../pages/404";
import Default from "../pages/Default";

/**
 * @description à modifier suivant les projets
 */
const DynamicTemplate = {
    'Template Home': Homepage,
    'Template Contact': Contact,
    'Template Agence': Agence,
    'Template Works': Works,
    'Template Jobs': Jobs,
    'Template Expertises': Expertises,
    'Template Projects': Cas,
    'Template 404': NotFound,
    'Template Default': Default
};

/**
 * @component
 * @description crer des routes en fonction des données JSON et utilise la correspondance nom côté back // nom component côté front
 * @param {object} route data des routes
 */
function RouteForTemplates(route) {
    // console.log(route);
    /*  let lang = useSelector(state => state.global).lang;
     let history = useHistory();
      let search = useLocation().search; // si recherche type http://monurl.com/recherche?=texte
      let hash = useLocation().hash; // si recherche avec # dans l'url*/

    /*useEffect(
        () => {
            switch (lang) {
                case 'fr':
                    history.replace({ pathname: route.path_fr, search, hash });
                    break;
                case 'en':
                    history.replace({ pathname: route.path_en, search, hash });
                    break;
                default:
                    break;
            }
        }, [lang]);*/

    return (
        <Route path={route.path} name={route.component} exact={route.exact} render={() =>
            React.createElement(DynamicTemplate[route.component], {
                key: route._uid,
                ...route.datas,
                _uid: route._uid,
                slug: route.computedMatch.params.id
            })
        } />
    );
}

export default RouteForTemplates;