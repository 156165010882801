import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Appear from "../../components/Appear";
import Effect from "../../components/Effect";
import Footer from "../../components/Footer";
// COMPONENTS
import Loader from "../../components/Loader";
import Metatags from "../../components/MetasTags";
import Navbar from "../../components/Navbar";
import NavbarContext from "../../components/Navbar/NavbarContext";
import Theme from "../../components/Theme";
import { updateGlobalStorePage } from "../../store/action";
import useApi from "../../utils/useApi";
import "./index.scss";

const Contact = ({ _uid }) => {
  const Navbar = NavbarContext.useContext();

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-contact"));
  }, [dispatch]);

  useEffect(() => {
    Navbar.setTheme("light");
  }, []);

  const location = useLocation();
  const [dataFetch, isLoaded] = useApi({
    slug: location.pathname,
    name: "page",
    _uid,
  });

  return (
    <Fragment>
      <Loader loading={!isLoaded} />
      {isLoaded && <ContactScreen data={dataFetch} />}
    </Fragment>
  );
};

function ContactScreen(props) {
  const { metas, title, hasSocial, mail } = props.data;

  return (
    <Fragment>
      <Metatags {...metas} />
      <Theme theme="bg-blue" className="contact">
        <Navbar.Spacer />
        <div className="contact__contents">
          <div>
            <Appear
              className="typo--extra-fat"
              html={title}
              effect={Effect.Ping.effects.contact}
            ></Appear>
          </div>
          <div>
            <a
              className="contact__email-link typo--fat"
              href={`mailto:${mail}`}
            >
              {mail}
            </a>
          </div>
        </div>
      </Theme>
      <Footer mode={"unfolded"} />
    </Fragment>
  );
}

export default Contact;
