import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./index.scss";

export default function Button(props) {
  const { theme = "dark" } = props;

  return (
    <Link
      className={classNames("button", `button--theme-${theme}`)}
      to={props.href}
    >
      <div className={"button__mouseout"}>
        <div className="button__content">{props.children}</div>
      </div>
      <div className={"button__mousein"}>
        <div className="button__content">{props.children}</div>
      </div>
    </Link>
  );
}

Button.propTypes = {
  theme: PropTypes.oneOf(["light"]).isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
