import React from "react"
import './index.scss';
import GlobalContainer from "../../../containers/GlobalContainer";

const Wysiwyg = ({content}) => {
    return (
        <div className="content__wysiwyg">
            <GlobalContainer max={true}>
                <div className="content__wysiwyg-inner" dangerouslySetInnerHTML={{__html: content}}></div>
            </GlobalContainer>
        </div>
    )
}

export default Wysiwyg