import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateGlobalStorePage } from "../../store/action";
import useApi from "../../utils/useApi";

// COMPONENTS
import Loader from "../../components/Loader";
import Metatags from "../../components/MetasTags";
import Banner from "../../components/Banner";
import ComponentByJSON from "../../utils/createComponentsByJSON";

const Cas = ({ _uid, slug }) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("single-project"));
  }, [dispatch]);

  const [dataFetch, isLoaded] = useApi({
    slug: slug,
    name: "projects/single",
    _uid,
  });
  const { metas, banner, flexibles } = dataFetch;

  function useWindowSize() {
    const [size, setSize] = useState(window.innerWidth);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }

      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const getWidth = useWindowSize();

  function renderPhoto() {
    if (getWidth < 764) {
      return banner.photosSrc.mobile;
    } else if (getWidth < 1024) {
      return banner.photosSrc.tablet;
    } else {
      return banner.photosSrc.desktop;
    }
  }

  function renderVideo() {
    if (getWidth < 764) {
      return banner.videosSrc.mobile;
    } else if (getWidth < 1024) {
      return banner.videosSrc.tablet;
    } else {
      return banner.videosSrc.desktop;
    }
  }

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...metas} />
          <Banner
            subject={banner.customer}
            title={banner.title}
            backgroundVideo={banner.video}
            srcVideo={renderVideo()}
            srcPhoto={renderPhoto()}
          />

          {flexibles && flexibles.map((block) => ComponentByJSON(block))}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Cas;
