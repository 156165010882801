import React from 'react';

//Import React Slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

//Import Swiper lib
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


import './index.scss';

SwiperCore.use([Navigation, Scrollbar]);


const SliderSwiper = ({ type, images }) => {
    let settings = {};

    switch (type) {
        case 'arrows':
            settings = {
                centerMode: true,
                centerPadding: "80px",
                dots: false
            };
            break;
        default: // scrollbar mode => swiper
            break;
    }

    const ReduceImg = () => {
        const allImg = document.querySelectorAll(".slide-img");
        allImg.forEach((img) => {
            if (!img.classList.contains('reduce')) {
                img.classList.add('reduce')
            }
        })
    }

    const IncreaseImg = () => {
        const allImg = document.querySelectorAll(".slide-img");
        allImg.forEach((img) => {
            if (img.classList.contains('reduce')) {
                img.classList.remove('reduce')
            }
        })
    }

    return (
        <>
            {type === 'arrows' ? (
                <div className={`content__slider content__slider--arrows`}>
                    <Slider {...settings} className="content__slider-arrows">
                        {
                            images.map((img, key) => {
                                return (
                                    <div key={key}>
                                        <img src={img.url} alt={img.alt} />
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            ) : (
                    <Swiper
                        spaceBetween={10}
                        speed={100}
                        initialSlide={1}
                        slidesPerView={2}
                        centeredSlides={true}
                        noSwiping={false}
                        scrollbar={{ draggable: true }}
                        onSliderMove={() => ReduceImg()}
                        onTouchEnd={() => IncreaseImg()}
                        freeMode={true}
                        resistance={false}
                        freeModeMomentumRatio={1}
                        freeModeMomentumVelocityRatio={0.5}
                        freeModeMomentumBounceRatio={1.5}
                        breakpoints={{
                            991: {
                                spaceBetween: 70,
                                slidesPerView: 2.8
                            }
                        }}
                    >
                        {
                            images.map((image, key) => {
                                return (
                                    <SwiperSlide key={key}>
                                        <img className="slide-img" src={image.url} alt={image.alt} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                )
            }
        </>
    )
}

export default SliderSwiper