import React from "react";
import "./index.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoaderSpinner from "react-loader-spinner";
import { CSSTransition } from "react-transition-group";

export default function Loader(props) {
  const { loading } = props;
  return (
    <CSSTransition
      in={loading}
      className={"loader"}
      timeout={200}
      unmountOnExit
    >
      <div>
        <LoaderSpinner
          type="ThreeDots"
          color="#000000"
          height={100}
          width={100}
          timeout={3000}
        />
      </div>
    </CSSTransition>
  );
}
