import React from "react"
import './index.scss';

import Fade from 'react-reveal/Fade';

import GlobalContainer from '../../containers/GlobalContainer/index'

function BlockTextAgenceStrong(props) {

    return (
        <div className="block-text-agence-strong__container">
            <GlobalContainer>
                <Fade bottom delay={600}>
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
                    </div>
                </Fade>
            </GlobalContainer>
        </div>

    )
}

export default BlockTextAgenceStrong
